.input-invalid {
  border-color: #FF4D4F !important;
  box-shadow: 0 0 5px rgba(255, 77, 80, 0.623) !important;
  -webkit-appearance: none;
}

.btn-disabled {
  background: #F5F5F5;
  color: #D9D9D9;
}

p.otp-text {
  text-align: center;
}

p.otp-text.disabled {
  color: #D9D9D9;
}

.input-center {
  text-align: center;
}

.input-center.otp, .input-center:focus {
  letter-spacing: 4px;
}