.btn-disabled {
  background: #F5F5F5;
  color: #D9D9D9;
}

.try-again {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-invalid {
  border-color: #FF4D4F !important;
  box-shadow: 0 0 5px rgba(255, 77, 80, 0.623) !important;
  -webkit-appearance: none;
}

#wrap-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vh;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
}

#loader {
  position: fixed;
  left: 52%;
  top: 48%;
  z-index: 2;
  width: 70px;
  height: 70px;
  margin: -50px 0 0 -50px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #E4007E;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-fade {
  position: relative;
  -webkit-animation-name: animatefade;
  -webkit-animation-duration: 1s;
  animation-name: animatefade;
  animation-duration: 1s
}

@-webkit-keyframes animatefade {
  from { opacity:0 } 
  to { opacity:1 }
}

@keyframes animatefade { 
  from{ opacity:0 } 
  to{ opacity:1 }
}

.submit-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: #FFFFFF;
  border-top: 1px solid #CCC;
}

.collapse {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
}

.arrow-down {
  margin-top: 10px;
  margin-right: 10px;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e4007db4;
}
