.logo {
  width: 20%;
  margin: 5%;
}

.title {
  text-align: center;
  /* color: #555555; */
  font-weight: bold;
  font-size: 1.3em;
}

.title-detail {
  text-align: center;
  margin: 0px 15px;
  opacity: 0.7;
  font-size: 0.75rem;
}

.year {
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  /* color: #555555; */
}
.year span {
  text-align: center;
  font-weight: bolder;
  font-size: 1em;
  /* color: #555555; */
}

.line {
  width: 100%;
  /* height: 50%; */
  border-bottom: 1px solid #555555;
  position: flex;
}

.content {
  margin-top: 5%;
  margin-bottom: 5%;
}

.content-box {
  display: flex;
  margin-top: 5%;
}

.content-main {
  width: 100%;
  text-align: left;
  margin-left: 5%;
}

.content-main-sub {
  font-size: 1.1em;
}

.content-left {
  text-align: right;
  margin-right: 5%;
}

.content-sub {
  margin: auto;
}

.content-sub span {
  font-weight: bold;
}

.btn {
  background-color: #e4007e;
  border: 0;
  color: #f3f3f3;
  border-radius: 5px;
  text-align: center;
  padding: 1vh 3vw;
  max-width: 150px;
  margin-right: 10%;
}

.btn-box {
  margin-left: 10px;
  margin-right: 5%;
  text-align: right;
}

#wrap-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vh;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
}

#loader {
  position: fixed;
  left: 52%;
  top: 48%;
  z-index: 2;
  width: 70px;
  height: 70px;
  margin: -50px 0 0 -50px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #e4007e;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
