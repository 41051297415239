@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.logo-mtl {
  width: 15%;
  margin-top: 5%;
  margin-left: 2%;
  margin-bottom: 5%;
}
.wrap-all {
  font-family: 'KaLaTeXaText_Bold';
  font-style: normal;
  background-image: none !important;
  color: #58595b;
}
.wrap-content {
  padding-top: 0px !important;
}
.wrap-content .header {
  font-weight: bold;
  font-size: 18px;
  color: #e4007e;
  text-align: center;
  line-height: 1.5;
}

.consent-submit {
  width: 136px;
  height: 35px;
  margin: auto;
  color: #ffffff;
  background: #e6007e;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consent-submit-inactive {
  width: 136px;
  height: 35px;
  margin: auto;
  color: #ffffff;
  background: #484647;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.box-consent {
  width: 100%;
  margin: 5% 0 5% 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #58595b;
}

.box-consent .select-btn {
  width: 182.77px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 12% !important;
  background: #e6007e;
  border-radius: 6px;
  color: #ffffff;
  line-height: 28px;
  margin: auto;
  font-size: 18px;
}

.box-consent .submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 247.23px;
  height: 42px;
  margin: 8% auto !important;
  background: #e6007e;
  border-radius: 6px;
  color: #ffffff;
  line-height: 22px;
  font-size: 14px;
}

.box-consent .rule {
  margin-top: 14%;
  font-weight: 800;
  font-size: 15px;
  line-height: 151.4%;
  text-align: start !important;
}

.text-content {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text-content-2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.box-consent .text-content {
  font-size: 16px;
  font-weight: 800;
}

.box-consent .border-box {
  margin-left: 10px;
  width: 108px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #2b3b8b;
  font-size: 20px;
}

.box-consent .download {
  width: 154px;
  height: 31px;
  margin-top: 10%;
  background: #008eff;
  border-radius: 27px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.highlight {
  text-align: center;
  font-weight: 800;
  color: #008eff !important;
}

.main-info {
  width: 70%;
  margin: 10px auto;
  font-size: 14px;
}

.main-info .content-left {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #58595b;
}

.main-info .content-right {
  float: right;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #e6007e;
}
.text-head {
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #e6007e;
  margin-top: 5%;
  border-bottom: 1px solid #e5e5e5;
}

.text-head-2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #e6007e;
  margin-top: 5%;
  border-bottom: 1px solid #e5e5e5;
}

.ps-content {
  margin-top: 10%;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #58595b;
}

.radio-consent {
  display: block !important;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
}

.radio-consent .content {
  margin-top: 0px;
  width: 92%;
  float: right;
}

.consent-line {
  width: 100%;
  height: 0px;
  float: right;
  border-bottom: 1px solid #c4c4c4;
}
.radio-consent .consent-btn {
  background: #ffffff;
  border: 1px solid #008eff;
  color: #008eff;
  box-sizing: border-box;
  width: 95px;
  height: 25px;
  float: right;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-consent .consent-btn-inactive {
  background: #ffffff;
  border: 1px solid #575a5d;
  color: #575a5d;
  box-sizing: border-box;
  width: 95px;
  height: 25px;
  float: right;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  pointer-events: none;
}

.radio input[type='radio'] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type='radio']:checked {
  background-color: #008eff;
  border-color: #008eff;
}

.checkbox-warp {
  margin: auto;
  margin-top: 5%;
  width: 85%;
}

.checkbox-warp .warp-text {
  width: 90%;
  margin: 0px auto;
  margin-left: 2%;
  float: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
}
.warp-text .content-left {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height */
  color: #2b3b8b;
}

.warp-text .content-right {
  text-align: right;
  width: 33vw;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #2b3b8b;
}
.warp-text .text {
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #2b3b8b;
}

.warp-modal {
  font-family: 'KaLaTeXaText_Bold';
  font-style: normal;
  color: #58595b;
  text-align: center;
}

.wrap-modal .header {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #e4007e;
  text-align: center;
}

.modal-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 5% 4% 5% 4%;
  color: #58595b;
}

.modal-highlight {
  width: 90%;
  margin: 8% auto;
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: #2b3b8b;
}

.modal-ps {
  width: 88%;
  margin: auto;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #58595b;
}
.modal-btn {
  display: flex;
  align-items: center;
  width: 80%;
  text-align: center;
  margin: 8% auto;
}
.modal-submit {
  display: flex;
  cursor: pointer;
  margin-left: 24%;
  float: right;
  width: 98px;
  height: 29px;
  background: #e6007e;
  color: #ffffff;
  border-radius: 43px;
}
.modal-submit-inactive {
  display: flex;
  cursor: pointer;
  margin-left: 24%;
  float: right;
  width: 98px;
  height: 29px;
  background: #757173;
  color: #ffffff;
  border-radius: 43px;
}
.modal-error {
  display: flex;
  margin: auto;
  cursor: pointer;
  width: 98px;
  height: 29px;
  background: #e6001f;
  color: #ffffff;
  border-radius: 43px;
}
