html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "kalatexatext_regular", sans-serif;
  background-color: #fafafa;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: #fff;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.wrap-all {
  max-width: 768px;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

.wrap-content {
  width: 90%;
  margin: 0 auto;
  padding-left: 0;
  padding-top: 5%;
  text-align: left;
}

.wrap-content.wrap-barcode.portrait {
  width: 95%;
  padding-left: 0;
  padding-top: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.wrap-content.wrap-barcode.landscape {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.logo-mtl {
  width: 15%;
  margin-bottom: 5%;
}
.logo-mtl img {
  width: 100%;
}
.consent-img {
  width: 60%;
  margin: 0 auto;
}
.consent-img img {
  width: 100%;
}
.wrap-content p {
  font-family: "kalatexatext_regular";
  line-height: 1.4;
  color: #000000;
  font-size: 20px;
  margin-top: 2%;
  color: #4a4a4a;
}
.wrap-content h3 {
  font-family: "kalatexatext_bold";
  font-size: 25px;
  color: #e6007e;
  line-height: 1.3;
  margin-top: 3%;
}
.agree-wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
  position: sticky;
}
.btn-agree {
  width: 25%;
  padding: 4%;
  background-color: #e6007e;
  border-radius: 30px;
  color: #fff;
  font-size: 20px;
  line-height: 0;
  font-family: "kalatexatext_bold";
}
.btn-not-agree {
  background-color: transparent;
  color: #4a4a4a;
}
.intro-content p {
  text-align: left;
}
.intro-content b {
  font-size: 20px;
}
b.intro-text {
  color: #00c0f2;
}
.next-btn {
  width: 20%;
  margin: 10% 0 5% auto;
  text-align: center;
}

/*barcode page*/
.barcode-head {
  width: 100%;
  position: relative;
}

.portrait .barcode-head {
  width: 90vh;
}

.barcode-btn {
  width: 25%;
  background-color: #f2f2f2;
  color: #e6007e;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  border-radius: 7px;
  padding: 2%;
  position: absolute;
  top: 10%;
  right: 0;
  white-space: nowrap;
}

.portrait .barcode-btn {
  width: 20%;
}

.wrap-barcode {
  text-align: center;
}
.wrap-barcode h3 {
  font-size: 26px;
  line-height: 1.5;
  margin-bottom: 3%;
}

.wrap-barcode.portrait h3 {
  width: 100vh;
}

.barcode-content-wrap {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  color: #4a4a4a;
  font-size: 20px;
}

.portrait .barcode-content-wrap {
  margin: 0;
  margin-left: 3.5vh;
  margin-right: 6.5vh;
  width: 90vh;
}

.barcode-content-left {
  width: 50%;
  text-align: left;
}
.barcode-content-right {
  width: 50%;
  text-align: right;
}
.barcode-img {
  width: 100%;
  margin: 3% 0;
}

.portrait .barcode-img {
  width: 100%;
  margin: 0;
  text-align: center;
}

.portrait .barcode-img svg {
  width: 95vh;
}

.barcode-img img {
  width: 100%;
}

/*payment page*/
.payment-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.payment-back-btn {
  position: unset;
  width: 20%;
}
.payment-wrap h4 {
  font-size: 22px;
  color: #2f2870;
  line-height: 1.3;
  margin-top: 5%;
  margin-bottom: 5%;
}
.payment-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.pay-logo {
  width: 30%;
}
.pay-logo img {
  width: 100%;
}
.pay-info {
  width: 65%;
  text-align: left;
}
.pay-info p {
  line-height: 1.2;
  font-size: 18px;
}
.payment-last {
  width: 50%;
}
p.pay-note {
  width: 100%;
  text-align: left;
  color: #2f2870;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 5%;
}

/*QR Code page*/
.qr-content {
  width: 100%;
}
.qr-img {
  margin: 0 auto;
}
.qr-img img {
  width: 100%;
}
.qr-wrap h3 {
  margin-bottom: 0;
}
.qr-wrap h4 {
  margin-top: 2%;
}
.qr-pay-logo {
  width: 80%;
  margin-bottom: 20%;
}
.qr-pay-last {
  width: 25%;
}
.qr-pay-info {
  width: 100%;
  text-align: center;
  margin-top: -5%;
}
p.qr-note {
  font-size: 20px;
}

.pay-info p span.remark-text {
  color: #2f2870;
}
