@media only screen and (max-width: 640px){
/*introduction page*/
	.next-btn{
		width: 23%;
		padding: 4.5%;
	}
	.intro-content p{
		margin-bottom: 5%;
	}
/*barcode page*/
	.barcode-btn {
	    min-width: 27%;
	    top: 15%;
	}
/*payment page*/
	.payment-back-btn{
		width: 20%;
	}
	.payment-wrap h4{
		font-size: 20px;
		line-height: 1.2;
	}

}
@media only screen and (max-width: 480px){
/*Consent page*/
	.logo-mtl {
	    width: 20%;
	}
	.consent-img {
	    width: 70%;
	}
	.wrap-content p, .btn-agree{
		font-size: 18px;
	}
	.btn-agree {
	    width: 30%;
	    padding: 5%;
	}
	.wrap-content h3{
	    font-size: 22px;
	}
/*barcode page*/
	.barcode-btn {
	  min-width: 30%;
	}
	.wrap-content h3 {
	    font-size: 20px;
	}
	.barcode-content-wrap{
		font-size: 18px;
	}

/*payment page*/
	.payment-back-btn{
		width: 27%;
		font-size: 16px;
	}	
	.pay-info p{
		line-height: 1.2;
    	font-size: 14px;
	}
	p.pay-note{
		font-size: 16px;
	}
	.qr-pay-logo{
		margin-bottom: 15%;
	}
	.qr-wrap h3 {
	    font-size: 22px;
	    margin-top: 5%;
	}

}
@media only screen and (max-width: 414px){
/*Consent page*/
	.logo-mtl {
	    width: 22%;
	}
	.consent-img {
	    width: 90%;
	}
	.wrap-content h3 {
	    font-size: 20px;
	}
	.btn-agree {
		width: 33%;
	    padding: 6%;
	}
/*barcode page*/
	.barcode-btn{
		min-width: 30%;
		font-size: 16px;
		top: 20%;
		padding: 2.7%;
		font-size: 14px;
	}
	.wrap-barcode h3{
		margin-top: 5%;
    	font-size: 18px;
	}
	.barcode-content-wrap {
	    font-size: 16px;
	}
	/*payment page*/
	.payment-back-btn {
	    width: 35%;
	    font-size: 16px;
	}
	.payment-content-wrap{
		flex-direction: column;
	}
	.payment-wrap h4 {
	    font-size: 18px;
	    line-height: 1.2;
	    margin-top: 8%;
    	margin-bottom: 5%;
	}	
	.pay-info p {
	    line-height: 1;
	    font-size: 16px;
	}
	.pay-logo {
	    width: 22%;
	}
	.pay-info {
	    width: 73%;
	}	
	.payment-last {
	    width: 100%;
	}			
	p.pay-note {
	    line-height: 1.4;
	}
	.payment-content-qr{
		flex-direction: row;
	}
	.qr-pay-logo {
	    width: 100%;
	}
	.qr-pay-info {
	    width: 70%;
	    margin: -5% auto 0 auto;
	}
	.qr-wrap h3{
	    margin-top: 6%;
	    font-size: 20px;
	}
	.qr-wrap h4 {
	    margin-top: 2%;
	}

}
@media only screen and (max-width: 375px){
/*Consent page*/
	.wrap-content p, .btn-agree {
	    font-size: 16px;
	}
/*barcode page*/
/*payment page*/
	.qr-wrap h3 {
	    font-size: 18px;
	}
	.qr-wrap h4{
		font-size: 16px;
	}
	.qr-pay-info p, p.qr-note{
		font-size: 14px;
	}
	.qr-pay-info {
	    width: 90%;
	    margin: -7% auto 0 auto;
	}


}
@media only screen and (max-width: 360px){

}

@media only screen and (max-width: 340px){
/*Consent page*/
	.wrap-content h3 {
	    font-size: 18px;
	}
	.btn-agree {
	    width: 37%;
	    padding: 7% 5%;
	}
/*barcode page*/
	.barcode-btn{
		min-width: 35%;
		width: auto;
		padding: 3%;
	}
	.wrap-content h3 {
	    font-size: 16px;
	}
	.barcode-content-wrap {
	    font-size: 14px;
	}
/*payment page*/
	.payment-wrap h4 {
	    font-size: 16px;
	    line-height: 1.2;
	}
	.pay-info p {
	    line-height: 1;
	    font-size: 14px;
	}
	p.pay-note {
	    font-size: 14px;
	}
	.pay-logo {
	    width: 25%;
	}
	.pay-info {
	    width: 70%;
	}	
	.payment-back-btn {
	    font-size: 14px;
	}
	.qr-pay-logo {
	    width: 100%;
	}

}

@media only screen and (max-width: 320px){
	.qr-pay-info p{
		font-size: 12px;
	}
}