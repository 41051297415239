@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-disabled {
  background: #F5F5F5;
  color: #D9D9D9;
}

.try-again {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-invalid {
  border-color: #FF4D4F !important;
  box-shadow: 0 0 5px rgba(255, 77, 80, 0.623) !important;
  -webkit-appearance: none;
}

#wrap-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vh;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
}

#loader {
  position: fixed;
  left: 52%;
  top: 48%;
  z-index: 2;
  width: 70px;
  height: 70px;
  margin: -50px 0 0 -50px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #E4007E;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-fade {
  position: relative;
  -webkit-animation-name: animatefade;
  -webkit-animation-duration: 1s;
  animation-name: animatefade;
  animation-duration: 1s
}

@-webkit-keyframes animatefade {
  from { opacity:0 } 
  to { opacity:1 }
}

@keyframes animatefade { 
  from{ opacity:0 } 
  to{ opacity:1 }
}

.submit-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: #FFFFFF;
  border-top: 1px solid #CCC;
}

.collapse {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
}

.arrow-down {
  margin-top: 10px;
  margin-right: 10px;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e4007db4;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "kalatexatext_regular", sans-serif;
  background-color: #fafafa;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: #fff;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.wrap-all {
  max-width: 768px;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

.wrap-content {
  width: 90%;
  margin: 0 auto;
  padding-left: 0;
  padding-top: 5%;
  text-align: left;
}

.wrap-content.wrap-barcode.portrait {
  width: 95%;
  padding-left: 0;
  padding-top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.wrap-content.wrap-barcode.landscape {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.logo-mtl {
  width: 15%;
  margin-bottom: 5%;
}
.logo-mtl img {
  width: 100%;
}
.consent-img {
  width: 60%;
  margin: 0 auto;
}
.consent-img img {
  width: 100%;
}
.wrap-content p {
  font-family: "kalatexatext_regular";
  line-height: 1.4;
  color: #000000;
  font-size: 20px;
  margin-top: 2%;
  color: #4a4a4a;
}
.wrap-content h3 {
  font-family: "kalatexatext_bold";
  font-size: 25px;
  color: #e6007e;
  line-height: 1.3;
  margin-top: 3%;
}
.agree-wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
}
.btn-agree {
  width: 25%;
  padding: 4%;
  background-color: #e6007e;
  border-radius: 30px;
  color: #fff;
  font-size: 20px;
  line-height: 0;
  font-family: "kalatexatext_bold";
}
.btn-not-agree {
  background-color: transparent;
  color: #4a4a4a;
}
.intro-content p {
  text-align: left;
}
.intro-content b {
  font-size: 20px;
}
b.intro-text {
  color: #00c0f2;
}
.next-btn {
  width: 20%;
  margin: 10% 0 5% auto;
  text-align: center;
}

/*barcode page*/
.barcode-head {
  width: 100%;
  position: relative;
}

.portrait .barcode-head {
  width: 90vh;
}

.barcode-btn {
  width: 25%;
  background-color: #f2f2f2;
  color: #e6007e;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  border-radius: 7px;
  padding: 2%;
  position: absolute;
  top: 10%;
  right: 0;
  white-space: nowrap;
}

.portrait .barcode-btn {
  width: 20%;
}

.wrap-barcode {
  text-align: center;
}
.wrap-barcode h3 {
  font-size: 26px;
  line-height: 1.5;
  margin-bottom: 3%;
}

.wrap-barcode.portrait h3 {
  width: 100vh;
}

.barcode-content-wrap {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  color: #4a4a4a;
  font-size: 20px;
}

.portrait .barcode-content-wrap {
  margin: 0;
  margin-left: 3.5vh;
  margin-right: 6.5vh;
  width: 90vh;
}

.barcode-content-left {
  width: 50%;
  text-align: left;
}
.barcode-content-right {
  width: 50%;
  text-align: right;
}
.barcode-img {
  width: 100%;
  margin: 3% 0;
}

.portrait .barcode-img {
  width: 100%;
  margin: 0;
  text-align: center;
}

.portrait .barcode-img svg {
  width: 95vh;
}

.barcode-img img {
  width: 100%;
}

/*payment page*/
.payment-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.payment-back-btn {
  position: unset;
  width: 20%;
}
.payment-wrap h4 {
  font-size: 22px;
  color: #2f2870;
  line-height: 1.3;
  margin-top: 5%;
  margin-bottom: 5%;
}
.payment-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.pay-logo {
  width: 30%;
}
.pay-logo img {
  width: 100%;
}
.pay-info {
  width: 65%;
  text-align: left;
}
.pay-info p {
  line-height: 1.2;
  font-size: 18px;
}
.payment-last {
  width: 50%;
}
p.pay-note {
  width: 100%;
  text-align: left;
  color: #2f2870;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 5%;
}

/*QR Code page*/
.qr-content {
  width: 100%;
}
.qr-img {
  margin: 0 auto;
}
.qr-img img {
  width: 100%;
}
.qr-wrap h3 {
  margin-bottom: 0;
}
.qr-wrap h4 {
  margin-top: 2%;
}
.qr-pay-logo {
  width: 80%;
  margin-bottom: 20%;
}
.qr-pay-last {
  width: 25%;
}
.qr-pay-info {
  width: 100%;
  text-align: center;
  margin-top: -5%;
}
p.qr-note {
  font-size: 20px;
}

.pay-info p span.remark-text {
  color: #2f2870;
}

@media only screen and (max-width: 640px){
/*introduction page*/
	.next-btn{
		width: 23%;
		padding: 4.5%;
	}
	.intro-content p{
		margin-bottom: 5%;
	}
/*barcode page*/
	.barcode-btn {
	    min-width: 27%;
	    top: 15%;
	}
/*payment page*/
	.payment-back-btn{
		width: 20%;
	}
	.payment-wrap h4{
		font-size: 20px;
		line-height: 1.2;
	}

}
@media only screen and (max-width: 480px){
/*Consent page*/
	.logo-mtl {
	    width: 20%;
	}
	.consent-img {
	    width: 70%;
	}
	.wrap-content p, .btn-agree{
		font-size: 18px;
	}
	.btn-agree {
	    width: 30%;
	    padding: 5%;
	}
	.wrap-content h3{
	    font-size: 22px;
	}
/*barcode page*/
	.barcode-btn {
	  min-width: 30%;
	}
	.wrap-content h3 {
	    font-size: 20px;
	}
	.barcode-content-wrap{
		font-size: 18px;
	}

/*payment page*/
	.payment-back-btn{
		width: 27%;
		font-size: 16px;
	}	
	.pay-info p{
		line-height: 1.2;
    	font-size: 14px;
	}
	p.pay-note{
		font-size: 16px;
	}
	.qr-pay-logo{
		margin-bottom: 15%;
	}
	.qr-wrap h3 {
	    font-size: 22px;
	    margin-top: 5%;
	}

}
@media only screen and (max-width: 414px){
/*Consent page*/
	.logo-mtl {
	    width: 22%;
	}
	.consent-img {
	    width: 90%;
	}
	.wrap-content h3 {
	    font-size: 20px;
	}
	.btn-agree {
		width: 33%;
	    padding: 6%;
	}
/*barcode page*/
	.barcode-btn{
		min-width: 30%;
		font-size: 16px;
		top: 20%;
		padding: 2.7%;
		font-size: 14px;
	}
	.wrap-barcode h3{
		margin-top: 5%;
    	font-size: 18px;
	}
	.barcode-content-wrap {
	    font-size: 16px;
	}
	/*payment page*/
	.payment-back-btn {
	    width: 35%;
	    font-size: 16px;
	}
	.payment-content-wrap{
		flex-direction: column;
	}
	.payment-wrap h4 {
	    font-size: 18px;
	    line-height: 1.2;
	    margin-top: 8%;
    	margin-bottom: 5%;
	}	
	.pay-info p {
	    line-height: 1;
	    font-size: 16px;
	}
	.pay-logo {
	    width: 22%;
	}
	.pay-info {
	    width: 73%;
	}	
	.payment-last {
	    width: 100%;
	}			
	p.pay-note {
	    line-height: 1.4;
	}
	.payment-content-qr{
		flex-direction: row;
	}
	.qr-pay-logo {
	    width: 100%;
	}
	.qr-pay-info {
	    width: 70%;
	    margin: -5% auto 0 auto;
	}
	.qr-wrap h3{
	    margin-top: 6%;
	    font-size: 20px;
	}
	.qr-wrap h4 {
	    margin-top: 2%;
	}

}
@media only screen and (max-width: 375px){
/*Consent page*/
	.wrap-content p, .btn-agree {
	    font-size: 16px;
	}
/*barcode page*/
/*payment page*/
	.qr-wrap h3 {
	    font-size: 18px;
	}
	.qr-wrap h4{
		font-size: 16px;
	}
	.qr-pay-info p, p.qr-note{
		font-size: 14px;
	}
	.qr-pay-info {
	    width: 90%;
	    margin: -7% auto 0 auto;
	}


}
@media only screen and (max-width: 360px){

}

@media only screen and (max-width: 340px){
/*Consent page*/
	.wrap-content h3 {
	    font-size: 18px;
	}
	.btn-agree {
	    width: 37%;
	    padding: 7% 5%;
	}
/*barcode page*/
	.barcode-btn{
		min-width: 35%;
		width: auto;
		padding: 3%;
	}
	.wrap-content h3 {
	    font-size: 16px;
	}
	.barcode-content-wrap {
	    font-size: 14px;
	}
/*payment page*/
	.payment-wrap h4 {
	    font-size: 16px;
	    line-height: 1.2;
	}
	.pay-info p {
	    line-height: 1;
	    font-size: 14px;
	}
	p.pay-note {
	    font-size: 14px;
	}
	.pay-logo {
	    width: 25%;
	}
	.pay-info {
	    width: 70%;
	}	
	.payment-back-btn {
	    font-size: 14px;
	}
	.qr-pay-logo {
	    width: 100%;
	}

}

@media only screen and (max-width: 320px){
	.qr-pay-info p{
		font-size: 12px;
	}
}
.input-invalid {
  border-color: #FF4D4F !important;
  box-shadow: 0 0 5px rgba(255, 77, 80, 0.623) !important;
  -webkit-appearance: none;
}

.btn-disabled {
  background: #F5F5F5;
  color: #D9D9D9;
}

p.otp-text {
  text-align: center;
}

p.otp-text.disabled {
  color: #D9D9D9;
}

.input-center {
  text-align: center;
}

.input-center.otp, .input-center:focus {
  letter-spacing: 4px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logo {
  width: 20%;
  margin: 5%;
}

.title {
  text-align: center;
  /* color: #555555; */
  font-weight: bold;
  font-size: 1.3em;
}

.title-detail {
  text-align: center;
  margin: 0px 15px;
  opacity: 0.7;
  font-size: 0.75rem;
}

.year {
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  /* color: #555555; */
}
.year span {
  text-align: center;
  font-weight: bolder;
  font-size: 1em;
  /* color: #555555; */
}

.line {
  width: 100%;
  /* height: 50%; */
  border-bottom: 1px solid #555555;
  position: flex;
}

.content {
  margin-top: 5%;
  margin-bottom: 5%;
}

.content-box {
  display: flex;
  margin-top: 5%;
}

.content-main {
  width: 100%;
  text-align: left;
  margin-left: 5%;
}

.content-main-sub {
  font-size: 1.1em;
}

.content-left {
  text-align: right;
  margin-right: 5%;
}

.content-sub {
  margin: auto;
}

.content-sub span {
  font-weight: bold;
}

.btn {
  background-color: #e4007e;
  border: 0;
  color: #f3f3f3;
  border-radius: 5px;
  text-align: center;
  padding: 1vh 3vw;
  max-width: 150px;
  margin-right: 10%;
}

.btn-box {
  margin-left: 10px;
  margin-right: 5%;
  text-align: right;
}

#wrap-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vh;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
}

#loader {
  position: fixed;
  left: 52%;
  top: 48%;
  z-index: 2;
  width: 70px;
  height: 70px;
  margin: -50px 0 0 -50px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #e4007e;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.logo-mtl {
  width: 15%;
  margin-top: 5%;
  margin-left: 2%;
  margin-bottom: 5%;
}
.wrap-all {
  font-family: 'KaLaTeXaText_Bold';
  font-style: normal;
  background-image: none !important;
  color: #58595b;
}
.wrap-content {
  padding-top: 0px !important;
}
.wrap-content .header {
  font-weight: bold;
  font-size: 18px;
  color: #e4007e;
  text-align: center;
  line-height: 1.5;
}

.consent-submit {
  width: 136px;
  height: 35px;
  margin: auto;
  color: #ffffff;
  background: #e6007e;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consent-submit-inactive {
  width: 136px;
  height: 35px;
  margin: auto;
  color: #ffffff;
  background: #484647;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.box-consent {
  width: 100%;
  margin: 5% 0 5% 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #58595b;
}

.box-consent .select-btn {
  width: 182.77px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 12% !important;
  background: #e6007e;
  border-radius: 6px;
  color: #ffffff;
  line-height: 28px;
  margin: auto;
  font-size: 18px;
}

.box-consent .submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 247.23px;
  height: 42px;
  margin: 8% auto !important;
  background: #e6007e;
  border-radius: 6px;
  color: #ffffff;
  line-height: 22px;
  font-size: 14px;
}

.box-consent .rule {
  margin-top: 14%;
  font-weight: 800;
  font-size: 15px;
  line-height: 151.4%;
  text-align: start !important;
}

.text-content {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text-content-2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.box-consent .text-content {
  font-size: 16px;
  font-weight: 800;
}

.box-consent .border-box {
  margin-left: 10px;
  width: 108px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #2b3b8b;
  font-size: 20px;
}

.box-consent .download {
  width: 154px;
  height: 31px;
  margin-top: 10%;
  background: #008eff;
  border-radius: 27px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.highlight {
  text-align: center;
  font-weight: 800;
  color: #008eff !important;
}

.main-info {
  width: 70%;
  margin: 10px auto;
  font-size: 14px;
}

.main-info .content-left {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #58595b;
}

.main-info .content-right {
  float: right;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #e6007e;
}
.text-head {
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #e6007e;
  margin-top: 5%;
  border-bottom: 1px solid #e5e5e5;
}

.text-head-2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #e6007e;
  margin-top: 5%;
  border-bottom: 1px solid #e5e5e5;
}

.ps-content {
  margin-top: 10%;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #58595b;
}

.radio-consent {
  display: block !important;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
}

.radio-consent .content {
  margin-top: 0px;
  width: 92%;
  float: right;
}

.consent-line {
  width: 100%;
  height: 0px;
  float: right;
  border-bottom: 1px solid #c4c4c4;
}
.radio-consent .consent-btn {
  background: #ffffff;
  border: 1px solid #008eff;
  color: #008eff;
  box-sizing: border-box;
  width: 95px;
  height: 25px;
  float: right;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-consent .consent-btn-inactive {
  background: #ffffff;
  border: 1px solid #575a5d;
  color: #575a5d;
  box-sizing: border-box;
  width: 95px;
  height: 25px;
  float: right;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  pointer-events: none;
}

.radio input[type='radio'] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type='radio']:checked {
  background-color: #008eff;
  border-color: #008eff;
}

.checkbox-warp {
  margin: auto;
  margin-top: 5%;
  width: 85%;
}

.checkbox-warp .warp-text {
  width: 90%;
  margin: 0px auto;
  margin-left: 2%;
  float: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
}
.warp-text .content-left {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height */
  color: #2b3b8b;
}

.warp-text .content-right {
  text-align: right;
  width: 33vw;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #2b3b8b;
}
.warp-text .text {
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #2b3b8b;
}

.warp-modal {
  font-family: 'KaLaTeXaText_Bold';
  font-style: normal;
  color: #58595b;
  text-align: center;
}

.wrap-modal .header {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #e4007e;
  text-align: center;
}

.modal-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 5% 4% 5% 4%;
  color: #58595b;
}

.modal-highlight {
  width: 90%;
  margin: 8% auto;
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: #2b3b8b;
}

.modal-ps {
  width: 88%;
  margin: auto;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #58595b;
}
.modal-btn {
  display: flex;
  align-items: center;
  width: 80%;
  text-align: center;
  margin: 8% auto;
}
.modal-submit {
  display: flex;
  cursor: pointer;
  margin-left: 24%;
  float: right;
  width: 98px;
  height: 29px;
  background: #e6007e;
  color: #ffffff;
  border-radius: 43px;
}
.modal-submit-inactive {
  display: flex;
  cursor: pointer;
  margin-left: 24%;
  float: right;
  width: 98px;
  height: 29px;
  background: #757173;
  color: #ffffff;
  border-radius: 43px;
}
.modal-error {
  display: flex;
  margin: auto;
  cursor: pointer;
  width: 98px;
  height: 29px;
  background: #e6001f;
  color: #ffffff;
  border-radius: 43px;
}

